import { hasPermission } from "utils/authorize";

const Can = ({ perform, data, children }) => {
    return children(hasPermission(perform, data));
};

Can.defaultProps = {
    children: () => null,
};

export default Can;
