import _ from "lodash";

export const createLoadingSelector = key => actions => state => {
  // returns true only when all actions is not loading
  return _(actions).some(action => {
    const computedAction = `meta.loading.${key ? `${key}/${action}` : action}`;
    const isLoading = _.get(state, computedAction);
    if (isLoading === undefined) {
      return true;
    }
    return isLoading;
  });
};

export const createErrorMessageSelector = key => actions => state => {
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  return (
    _(actions)
      .map(action => _.get(state, `meta.error.${key ? `${key}/${action}` : action}`))
      .compact()
      .first() || ""
  );
};
