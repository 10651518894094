import { JobPermissions } from "./permissions";

export default {
  SuperAdmin: {
    dynamic: {},
  },
  Admin: {
    dynamic: {},
  },
  Workers: {
    dynamic: {},
  },
  Employer: {
    dynamic: {},
  },
  EmployerManager: {
    dynamic: {},
    action: {
      [JobPermissions.read.list]: () => {},
    },
  },
};
