export function getPageInfoAttr({ prefix }) {
  return `${prefix}PageInfo`;
}

export function initialState(paginationSettings) {
  return Object.keys(paginationSettings).reduce((acc, key) => {
    const { defaultPageSize = 10 } = paginationSettings[key];
    acc[getPageInfoAttr(paginationSettings[key])] = {
      pageSize: defaultPageSize,
      currentPage: 0,
      isLoading: true,
      startCursor: "",
      endCursor: "",
      refreshCursor: "",
      totalCount: 0,
      pageCount: 0,
      hasNextPage: false,
      hasPreviousPage: false,
    };

    return acc;
  }, {});
}

export function createPaginationActions(paginationSettings) {
  return Object.keys(paginationSettings).reduce((acc, key) => {
    const prefix = paginationSettings[key].prefix.toUpperCase();
    const pageInfoAttr = getPageInfoAttr(paginationSettings[key]);
    return {
      ...acc,
      [`pagination/${prefix}/nextPage`]: state => {
        return {
          ...state,
          [pageInfoAttr]: {
            ...state[pageInfoAttr],
            currentPage: state[pageInfoAttr].currentPage + 1,
            isLoading: true,
          },
        };
      },
      [`pagination/${prefix}/prevPage`]: state => {
        return {
          ...state,
          [pageInfoAttr]: {
            ...state[pageInfoAttr],
            currentPage: state[pageInfoAttr].currentPage - 1,
            isLoading: true,
          },
        };
      },
      [`pagination/${prefix}/updatePageInfo`]: (state, action) => {
        const { pageInfo } = action.payload;

        const prevState = state[pageInfoAttr];

        const updatedPageInfo = {
          ...prevState,
          ...pageInfo,
          refreshCursor: prevState.endCursor,
        };

        return {
          ...state,
          [pageInfoAttr]: {
            ...updatedPageInfo,
            pageCount: Math.ceil(updatedPageInfo.totalCount / updatedPageInfo.pageSize),
          },
        };
      },
      [`pagination/${prefix}/doneLoading`]: state => {
        return {
          ...state,
          [pageInfoAttr]: {
            ...state[pageInfoAttr],
            isLoading: false,
          },
        };
      },
      [`pagination/${prefix}/isLoading`]: state => {
        return {
          ...state,
          [pageInfoAttr]: {
            ...state[pageInfoAttr],
            isLoading: true,
          },
        };
      },
      [`pagination/${prefix}/resetCurrentPage`]: state => {
        return {
          ...state,
          [pageInfoAttr]: {
            ...state[pageInfoAttr],
            currentPage: 0,
            startCursor: "",
            endCursor: "",
            refreshCursor: "",
            hasNextPage: false,
            hasPreviousPage: false,
            totalCount: 0,
            pageCount: 0,
          },
        };
      },
    };
  }, {});
}

export const updatePageInfo = ({ prefix }, pageInfo) => ({
  type: `pagination/${prefix.toUpperCase()}/updatePageInfo`,
  payload: { pageInfo },
});

export const isLoading = ({ prefix }) => ({
  type: `pagination/${prefix.toUpperCase()}/isLoading`,
});

export const doneLoading = ({ prefix }) => ({
  type: `pagination/${prefix.toUpperCase()}/doneLoading`,
});

export const resetCurrentPage = ({ prefix }) => ({
  type: `pagination/${prefix.toUpperCase()}/resetCurrentPage`,
});

export const nextPage = ({ prefix }) => ({
  type: `pagination/${prefix.toUpperCase()}/nextPage`,
});

export const prevPage = ({ prefix }) => ({
  type: `pagination/${prefix.toUpperCase()}/prevPage`,
});

export function pagingVarsFactory(setting) {
  return function getPagingVars(pageInfo, pageIndex) {
    return function handlePaging(dispatch) {
      if (pageIndex === undefined || pageIndex === 0) {
        dispatch(isLoading(setting));
        dispatch(resetCurrentPage(setting));
        return {
          first: pageInfo.pageSize,
        };
      } else if (pageInfo.currentPage === pageIndex) {
            dispatch(isLoading(setting));
            if (pageInfo.refreshCursor) {
                return {
                    first: pageInfo.pageSize,
                    after: pageInfo.refreshCursor,
                };
            }

            return {
                first: pageInfo.pageSize,
            };
        }


      if (pageInfo.currentPage < pageIndex) {
        dispatch(nextPage(setting));
        return {
          first: pageInfo.pageSize,
          after: pageInfo.endCursor,
        };
      }

      if (pageInfo.currentPage > pageIndex) {
        dispatch(prevPage(setting));
        return {
          last: pageInfo.pageSize,
          before: pageInfo.startCursor,
        };
      }

      throw new Error("Unknown pagination error");
    };
  };
}
