import isArray from "lodash/isArray";
import isObject from "lodash/isObject";

export function flattenGraphQLArray({ edges = [] } = {}) {
  return edges.map(flattenGraphQLNode);
}

export function reorderArray(oldIndex, newIndex, originalArray) {
  const movedItem = originalArray.find((item, index) => index === oldIndex);
  const remainingItems = originalArray.filter((item, index) => index !== oldIndex);
  const reorderedItems = [
    ...remainingItems.slice(0, newIndex),
    movedItem,
    ...remainingItems.slice(newIndex),
  ];
  return reorderedItems;
}

export function flattenGraphQLArrayAsObject(arr) {
  return arr.edges.reduce((acc, { node }) => {
    if (!node.id) {
      throw new Error("Primary key must be provided");
    }

    acc[node.id] = flattenGraphQLNode(node);
    return acc;
  }, {});
}

export function flattenGraphQLNode({ node = {} } = {}) {
  return Object.keys(node).reduce((acc, key) => {
    if (node[key] !== null) {
      if (node[key].hasOwnProperty("totalCount")) {
        const countKeyName = `${key}Count`;
        acc[countKeyName] = node[key].totalCount;
      }

      if (node[key].hasOwnProperty("pageInfo")) {
        const pageInfoKeyName = `${key}PageInfo`;
        acc[pageInfoKeyName] = node[key].pageInfo;
      }

      if (node[key].edges !== undefined) {
        acc[key] = flattenGraphQLArray(node[key]);
      }
    }

    if (isObject(node[key]) && !isArray(node[key])) {
      acc[key] = flattenGraphQLNode(node[key]);
    }

    acc[key] = node[key];

    return acc;
  }, {});
}
