import React from 'react';
import { MobileTimePicker as MUIMobileTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

export default function MobileTimePicker({ onChange, ...props }) {
    return (
        <MUIMobileTimePicker
            {...props}
            onChange={e => onChange(moment(e))}
        />
    )
};