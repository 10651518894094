import {gql} from "@apollo/client";

export default gql`
  query calculateEmployerBill($data: CalculateEmployerBillInput!) {
    calculateEmployerBill(data: $data) {
      hourlyPay
      regularPayHours
      overtimePay
      overtimePayHours
      doublePay
      doublePayHours
      breakPay
      gfFee
      gstAmount
      gstPercentage
      total
    }
  }
`;