import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer from "store/rootReducer";

const composeEnhancers = composeWithDevTools;

const middlewares = [thunk];

middlewares
  .push
  //   createLogger({
  //     collapsed: true,
  //     duration: true,
  //     timestamp: false,
  //   })
  ();

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["*"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, enhancer);
export const persistor = persistStore(store);

if (module.hot) {
  module.hot.accept(() => {
    const nextRootReducer = require("./rootReducer").default;
    store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
  });
}
