// import React from "react";
import types from "./types";

export const enqueueSnackbar = notification => ({
  type: types.ENQUEUE_SNACKBAR,
  notification: {
    key: new Date().getTime() + Math.random(),
    ...notification,
  },
});

export const successToast = message => ({
  type: types.ENQUEUE_SNACKBAR,
  notification: {
    key: new Date().getTime() + Math.random(),
    message,
    options: {
      variant: "success",
    },
  },
});

export const errorToast = message => ({
  type: types.ENQUEUE_SNACKBAR,
  notification: {
    key: new Date().getTime() + Math.random(),
    message: message,
    options: {
      variant: "error",
    },
  },
});
export const infoToast = message => ({
  type: types.ENQUEUE_SNACKBAR,
  notification: {
    key: new Date().getTime() + Math.random(),
    message,
    options: {
      variant: "info",
    },
  },
});
export const warningToast = message => ({
  type: types.ENQUEUE_SNACKBAR,
  notification: {
    key: new Date().getTime() + Math.random(),
    message,
    options: {
      variant: "warning",
    },
  },
});
export const defaultToast = message => ({
  type: types.ENQUEUE_SNACKBAR,
  notification: {
    key: new Date().getTime() + Math.random(),
    message,
    options: {
      variant: "default",
    },
  },
});

export const removeSnackbar = key => ({
  type: types.REMOVE_SNACKBAR,
  key,
});
