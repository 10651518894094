import { combineReducers } from "redux";
import types from "./types";

const loadingReducer = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;

  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_JOBS_REQUEST
    //      and false when receiving GET_JOBS_SUCCESS / GET_JOBS_FAILURE
    [requestName]: requestState === "REQUEST",
  };
};

const errorReducer = (state = {}, action) => {
  const { type, payload } = action;
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);

  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store errorMessage
    // e.g. stores errorMessage when receiving GET_JOBS_FAILURE
    //      else clear errorMessage when receiving GET_JOBS_REQUEST
    [requestName]: requestState === "FAILURE" ? payload.message : "",
  };
};

const notificationReducer = (state = { app: [], user: [] }, action) => {
  switch (action.type) {
    case types.ENQUEUE_SNACKBAR:
      return {
        ...state,
        app: [...state.app, action.notification],
      };

    case types.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.app.filter(notification => notification.key !== action.key),
      };

    default:
      return state;
  }
};

export default combineReducers({
  loading: loadingReducer,
  error: errorReducer,
  notifications: notificationReducer,
});
