import React from "react";
import { connect } from "react-redux";
import { actions as LoginActions } from "store/modules/login";

class Callback extends React.PureComponent {
  componentDidMount() {
    this.props.handleAuthentication();
  }

  render() {
    return (
      <div className="spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    );
  }
}

const mapActions = {
  handleAuthentication: LoginActions.handleAuthentication,
};

export default connect(null, mapActions)(Callback);
