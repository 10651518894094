// import React from "react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { actions as LoginActions } from "store/modules/login";

class Logout extends Component {
  componentDidMount() {
    document.body.style.overflow = "unset";
    this.props.logout();
  }

  render() {
    return <React.Fragment />;
  }
}

Logout.propTypes = {
  logout: PropTypes.func,
};

const mapState = () => {
  return {};
};

const mapActions = { logout: LoginActions.logout };

export default connect(mapState, mapActions)(withRouter(Logout));
