import rules from "helpers/constants/roles";

export function createReducer(initialState, actionsMap) {
  return function genericReducer(state = initialState, action) {
    const reduceFn = actionsMap[action.type];
    if (reduceFn) {
      return reduceFn(state, action);
    }
    return state;
  };
}

export function createNetworkTypes(action, prefix) {
  if (!action) {
    throw new Error("Action must be specified before createNetworkTypes can be used.");
  }

  return {
    [`${action}_REQUEST`]: `${prefix ? `${prefix}/` : ""}${action}_REQUEST`,
    [`${action}_SUCCESS`]: `${prefix ? `${prefix}/` : ""}${action}_SUCCESS`,
    [`${action}_FAILURE`]: `${prefix ? `${prefix}/` : ""}${action}_FAILURE`,
  };
}

export function getRelation(relations, field) {
  const relation = Object.keys(relations).reduce((acc, relation) => {
    const relationArr = relations[relation];
    if (relationArr.includes(field)) {
      acc = relation;
    }
    return acc;
  }, "");

  return relation ? relation : undefined;
}

export function canExecute(state, action, data) {
  if (!action) {
    return true;
  }

  return state.login.roles.reduce((acc, role) => {
    if (acc) {
      return acc;
    }

    const permissions = rules[role];
    if (!permissions) {
      // role is not present in the rules
      return false;
    }

    const staticPermissions = permissions.staticAction;

    if (staticPermissions && staticPermissions.includes(action)) {
      // static rule provided for action
      return true;
    }

    const dynamicPermissions = permissions.dynamicAction;

    if (dynamicPermissions) {
      const permissionCondition = dynamicPermissions[action];
      if (!permissionCondition) {
        // dynamic rule not provided for action
        return false;
      }

      return permissionCondition(data);
    }
    return false;
  }, false);
}
